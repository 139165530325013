// require('bootstrap');

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('.recoverBtn').on('click', function() {
    const button = this;
    const domainId = $(button).data('id');
    $.post('scraper/despam/'+domainId+'', {}, function() {
        $('#row-'+ domainId +'').hide();
    });
});

$('.forceDeleteBtn').on('click', function() {
    const button = this;
    const domainId = $(button).data('id');

    $.post('scraper/force-delete/'+domainId+'', {}, function() {
        $('#row-'+ domainId +'').hide();
    });
});

$('.startScraper').on('click', function() {
    $.post('/scraper/run');
});

$('.btn-start-linkcheck').on('click', function() {
    $.post('/linkcheck/run');
});

$('.btn-add-domain').on('click', function() {
    const domainId = $(this).data('id');
    const button = this;
    $.post('/scraper/select/' + domainId, {}, function() {
        $(button).hide();
        $(button).prev().show();
    });
});

$('.btn-remove-domain').on('click', function() {
    const domainId = $(this).data('id');
    const button = this;
    $.post('/scraper/deselect/' + domainId, {}, function() {
        $(button).hide();
        $(button).next().show();
    });
});

$('.btn-spam-domain').on('click', function() {
    const domainId = $(this).data('id');
    const button = this;
    $.post('/scraper/spam/' + domainId, {}, function() {
        $(button).hide();
        $(button).prev().show();
    });
});

$('.btn-despam-domain').on('click', function() {
    const domainId = $(this).data('id');
    const button = this;
    $.post('/scraper/despam/' + domainId, {}, function() {
        $(button).hide();
        $(button).next().show();
    });
});

$('#email').on('keyup', function() {
    $.ajax({
    
        url:"/linkbuilding/search",

        type:"GET",
    
        data:{'email':$(this).val()},
        
        success:function (data) {
            $('#emailList').html(data);
        }
    })
});

$('.btn-add-admin').on('click', function() {
    const userId = $(this).data('id');
    const button = this;

    $.post('admin/add/admin/' + userId, {}, function() {
        $(button).hide();
        $(button).next().show();
    });
});

$('.btn-remove-admin').on('click', function() {
    const userId = $(this).data('id');
    const button = this;

    $.post('admin/remove/admin/' + userId, {}, function() {
        $(button).hide();
        $(button).prev().show();
    });
});

$('#addBtn').on('click', function() {
    var form = document.getElementById('addForm');
    var button = document.getElementById('addBtn');
    form.classList.remove("d-none");
    button.classList.add("d-none");
});

$('#closeMenu').on('click', function() {
    var form = document.getElementById('addForm');
    var button = document.getElementById('addBtn');
    form.classList.add("d-none");
    button.classList.remove("d-none");
});

function statusColor() {
    var scraperData = document.getElementById("scraper-status").textContent;

    if (scraperData == "Running") {
        return  "green";
    }
    if (scraperData == "Starting") {
        return "orange";
    } else {
        return "red";
    }
}

function statusLoop() {
    $.get("/status", function(data) {
        
        if(document.getElementById("scraper-status"))
        {
            $('#scraper-status').text(data[0]);
            $('#scraper-status').css('color', statusColor());
        } 

        for (let i = 0; i <= data[1].length; i++) 
        {
            if(data[1][i]){
                var id = Object.values(data[1][i])[0];
                var active = Object.values(data[1][i])[1];
                var toggledAt = Object.values(data[1][i])[2];
                var scannedAt = Object.values(data[1][i])[3];

                var elementActive = $('#row-'+id+'').children('.active');

                if(active == 1)
                {
                    elementActive.text('Active');
                    elementActive.css('color', 'green');
                } else if(active == 0) {
                    elementActive.text('Inactive');
                    elementActive.css('color', 'red');
                } else {
                    elementActive.text('Not Checked');
                }
                var elementToggled = $('#row-'+id+'').children('.toggled-at');

                if(toggledAt == null){
                    elementToggled.text('-');
                } else {
                    elementToggled.text(toggledAt);
                }

                var elementUpdated = $('#row-'+id+'').children('.scanned-at');

                if(scannedAt == null){
                    elementUpdated.text('-');
                } else {
                    elementUpdated.text(scannedAt);
                }
            }
        }
    });
    setTimeout(statusLoop, 5000);
}

statusLoop();

$('.run-active-link-check-btn').on('click', function() {
    $.post('/linkcheck/run');
});

$('.btn-start-scraper').on('click', function() {
    const settingValue = $(this).data('id');
    const button = this;
    $.post('/admin/panel/scraper/' + settingValue, {}, function() {
        $(button).hide();
        $(button).next().show();
    });
});

$('.btn-stop-scraper').on('click', function() {
    const settingValue = $(this).data('id');
    const button = this;
    $.post('/admin/panel/scraper/' + settingValue, {}, function() {
        $(button).hide();
        $(button).prev().show();
    });
});